@font-face {
    font-family: 'Moncler Gothic';
    font-weight: 600;
    src: url("./assets/fonts/MonclerGothic-Bold.otf") format("opentype");
}

@font-face {
    font-family: 'Moncler Gothic';
    font-weight: 400;
    src: url("./assets/fonts/MonclerGothic-Medium.otf") format("opentype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
